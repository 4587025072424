import dates from "./dates";
import files from "./files";
import forms from "./forms";
import quill from "./quill";
import requests from "./requests";

export default {
  dates,
  files,
  forms,
  quill,
  requests,
};
