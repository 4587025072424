import type { IValidation } from "@/interfaces";

export default (password: string, passwordRetry: string): IValidation => {
  const isContainSmallLetter = /[a-z]/.test(password);
  const isContainLargeLetter = /[A-Z]/.test(password);
  const isMatched = password === passwordRetry;
  const isValid = () => {
    if (!isMatched) {
      return false;
    }

    if (password.length < 6) {
      return false;
    }

    // At least two of them should matched
    const comparisons = [isContainSmallLetter, isContainLargeLetter].filter(
      (value: any) => !!value,
    );

    return comparisons.length >= 2;
  };

  return {
    isContainSmallLetter,
    isContainLargeLetter,
    isMatched,
    isValid: isValid(),
  };
};
