import { graphqlClient } from "@/apollo";
import { SaveResourceDocument } from "@/graphql/generated-types";
import { useAuthStore } from "@/stores/auth.module";
import { useActivitiesStore } from "@/stores/activities.module";
import { IActivitiesItem } from "@/stores/activities.module";

export const syncVideoResourceIfNeeded = async (
  activity: IActivitiesItem,
): Promise<IActivitiesItem> => {
  const authStore = useAuthStore();
  const activitiesStore = useActivitiesStore();

  const { institutionId } = authStore;
  const { videos } = activity;
  const { videoTabUrl } = activitiesStore.item;

  if (!institutionId) {
    return Promise.reject(
      new Error("Invalid institutionId when updating resource."),
    );
  }

  if (Array.isArray(videos) || !videos) {
    const [firstVideo] = videos || [];

    if (typeof firstVideo === "string") return activity; // If string a new ressource has already been created.
    if (firstVideo?.url && firstVideo?.url === videoTabUrl) return activity; // Activity already has a video and URL wasn't updated.

    try {
      const res = await graphqlClient.query({
        query: SaveResourceDocument,
        variables: {
          resource: {
            url: videoTabUrl,
            institution: institutionId,
          },
        },
        fetchPolicy: "no-cache",
      });

      const newVideoId = res.data.resource?.save?.id;

      if (newVideoId) {
        return { ...activity, videos: [newVideoId] };
      }
    } catch (error) {
      return Promise.reject(
        new Error(`Error while syncing Video resource with BE:: ${error}`),
      );
    }
  }
  return activity;
};
