/**
 * Blob to base64
 */
export default async (file: Blob): Promise<string> =>
  new Promise((resolve: any, reject: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (e: any) => reject(e);
  });
