export interface IOptions {
  accept?: string;
  multiple?: boolean;
}

export type FileSelectionCallback = (files: FileList | null) => void;

/**
 * Get user's file selections. It open a file selected input and return files
 */
export default (options: IOptions, callback: FileSelectionCallback) => {
  const { accept, multiple } = options;
  const input = document.createElement("input");
  input.type = "file";

  if (accept) {
    input.accept = accept;
  }

  if (multiple !== undefined) {
    input.multiple = multiple;
  }

  input.onchange = async function () {
    callback(input.files);
  };
  input.click();
};
