import { getUnixTime, format } from "date-fns";
import { da } from "date-fns/locale";

const formatToBrowserDateTime = (date: Date | number, options = {}) => {
  // It uses the navigator language selection. Browsers can not access to the
  // system settings because of security concerns.
  const dateFormat = new Intl.DateTimeFormat(navigator.language, options);
  return dateFormat.format(date);
};

export const convertToDate = (value: any) => {
  return typeof value === "number" ? toDate(value) : value;
};

export const getFullMonthName = (date: Date | number) => {
  const dateFormat = new Intl.DateTimeFormat(navigator.language, {
    month: "long",
  });
  return dateFormat.format(date);
};

/**
 * Convert unix timestamp to Date object
 *
 * @param unix number
 * @returns Date
 * @example
 *  <script setup lang="ts">
 *    const date: Date = toDate(1697700327)
 *  </script>
 */
export const toDate = (unix: number) => {
  return new Date(unix * 1000);
};

/**
 * Convert Date object to unix timestamp
 *
 * @param unix number
 * @returns Date
 * @example
 *  <script setup lang="ts">
 *    const value: number = toUnix(new Date())
 *  </script>
 */
export const toUnix = (date: any) => {
  return Math.round(getUnixTime(date));
};

export const toBrowserDate = (date: any, dateStyle = "short") => {
  return formatToBrowserDateTime(convertToDate(date), { dateStyle });
};

export const toBrowserTime = (date: any, timeStyle = "short") => {
  return formatToBrowserDateTime(convertToDate(date), { timeStyle });
};

export const toDayName = (date: any) => {
  const name = format(toDate(date), "iiii", {
    locale: da,
  });
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const toWeekNumber = (date: any) => {
  return format(toDate(date), "ww");
};

export const fromUnixToAPIDate = (date: any) => {
  return toAPIDate(toDate(date));
};

export const toAPIDate = (date: Date) => {
  return date.toISOString().split(".")[0] + "Z";
};
