import { graphqlClient } from "@/apollo";
import {
  GetActivitiesByRangeDocument,
  GetActivitiesByRangeQueryVariables,
  SaveActivityDocument,
  DeleteActivityDocument,
  NotificationSettingsInput,
  SaveActivityNotificationDocumentDocument,
} from "@/graphql/generated-types";
import { syncVideoResourceIfNeeded } from "@/services/resources/activities/syncVideoResourceIfNeeded";
import { IActivitiesItem } from "@/stores/activities.module";
import { toActivityInputType } from "@/stores/utils/type-transformers/activies/toActivityInputType";

type VisibilityOption = "visibility-on" | "visibility-off";

const getActivities = async (
  dateFrom: string,
  dateTo: string,
  visibility: VisibilityOption,
) => {
  const variables: GetActivitiesByRangeQueryVariables = {
    dateFrom,
    dateTo,
    filter: {
      departments: [],
      groups: [],
      institutions: [],
    },
    visibility: visibility === "visibility-on",
  };

  const response = await graphqlClient.query({
    query: GetActivitiesByRangeDocument,
    variables,
    fetchPolicy: "no-cache",
  });

  if (response.data.activity?.getActivitiesByFilters) {
    return response.data.activity.getActivitiesByFilters;
  }

  throw new Error("Query error");
};

const save = async (
  activity: IActivitiesItem,
  notification: NotificationSettingsInput,
) => {
  try {
    const resourceUpdatedActivity = await syncVideoResourceIfNeeded(activity);
    const activityAPIInput = toActivityInputType(resourceUpdatedActivity);
    const response = await graphqlClient.query({
      query: SaveActivityDocument,
      variables: {
        activity: activityAPIInput,
      },
      fetchPolicy: "no-cache",
    });

    if (!notification) {
      return response;
    }

    notification.entityId = response.data.activity?.save?.id;

    const notificationResponse = await graphqlClient.query({
      query: SaveActivityNotificationDocumentDocument,
      variables: {
        settings: notification,
      },
      fetchPolicy: "no-cache",
    });

    if (
      !notificationResponse.data.activity?.saveNotificationSettings?.success
    ) {
      throw new Error(`Saving the activity notification is not successfull`);
    }

    return response;
  } catch (error) {
    throw new Error(`Saving the activities is not successfull ${error}`);
  }
};

const deleteItem = async (id: string) => {
  const response = await graphqlClient.query({
    query: DeleteActivityDocument,
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.activity?.delete?.success) {
    return response;
  }

  throw new Error(`Deleting the activity is not successfull.`);
};

export default {
  getActivities,
  save,
  deleteItem,
};
