import deltaToHtml from "./deltaToHtml";
import deltaToText from "./deltaToText";
import getEmptyDelta from "./getEmptyDelta";
import htmlToDelta from "./htmlToDelta";

export default {
  deltaToHtml,
  deltaToText,
  getEmptyDelta,
  htmlToDelta,
};
