import { ActivityInput } from "@/graphql/generated-types";
import { IActivitiesItem } from "@/stores/activities.module";

export const toActivityInputType = (
  activity: IActivitiesItem,
): ActivityInput => {
  return {
    activityClub: activity.club?.id,
    departments: activity.departments?.map((dept) => dept.id) || [],
    description:
      typeof activity.description === "string"
        ? activity.description
        : undefined, // todo verify?
    employeesResponsible: activity.responsible?.map((emp) => emp.id) || [], // todo verify and maybe apply same logic to resident?
    employeesSignedUp: activity.participants?.map((emp) => emp.id) || [],
    endDate: activity.endDate,
    groups: activity.groups?.map((group) => group.id) || [],
    id: activity.id,
    image: activity.image?.id,
    institution: activity.institution, // Id is currently directly on the institution but should be on .id
    isRepeat: activity.isRepeat || false,
    location: activity.location,
    maxSignup: activity.maxSignups || undefined,
    notifyNokOnSignup: activity.notifyNokOnSignup,
    pdf: undefined, // Doesn't exist on SingleActivity where this from? should maybe add to IActivitiesItem
    pictogram: activity.pictograms?.[0]?.id || undefined, // ActivityInput has single value but SingleActivity has Maybe<Array<Pictogram>>
    pictures: activity.pictures?.map((pic) => pic.id) || [],
    price: activity.price?.[0]?.amount || undefined, // amount doesnt exist but price does?
    repeat: undefined, // ActivityInput repeat is single value but doesnt exist on singleActivity RepeatingActivity has RepeatRule as type
    residentsResponsible: [], // Activity: responsible?: Maybe<Array<Profile>> - See employeesResponsible we should map responsible to Res/Emp-responsible?
    residentsSignedUp: [], // Not defined in our types, add to IActivitiesItem?
    rrule: undefined, // ActivityInput InputMaybe<Scalars["String"]["input"]>; is this repeat: RepeatRule from RepeatingActivity?
    showDateEnd: activity.showDateEnd,
    showDateStart: activity.showDateStart,
    signup: activity.signupEnabled,
    signupEnd: activity.signupEnd,
    signupStart: activity.signupStart,
    startDate: activity.startDate,
    status: activity.status,
    title: activity.title,
    updateGroupPosts: undefined, // ActivityInput: InputMaybe<Scalars["Boolean"]["input"]>
    vacation: undefined, // ActivityInput: InputMaybe<Scalars["Boolean"]["input"]>
    videos:
      activity.videos?.map((video) =>
        typeof video === "string" ? video : video.id,
      ) || [],
  };
};
