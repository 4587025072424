import type { TabErrorSchemaTypes } from "@/types";

export default (schema: TabErrorSchemaTypes, fieldErrors: any) => {
  const fields = Object.keys(fieldErrors);
  const errors: Record<string, boolean> = {};

  Object.keys(schema).forEach((tabName: any) => {
    errors[tabName] = fields.some((field: any) =>
      (schema as any)[tabName].includes(field),
    );
  });

  return errors;
};
