import { defineStore, type Store } from "pinia";
import joi from "joi";
import { fromUnixToAPIDate, toDate, toUnix } from "../stories/utils/dates";
import { useAuthStore } from "./auth.module";
import {
  addSeconds,
  differenceInDays,
  format,
  isEqual,
  subDays,
  subSeconds,
} from "date-fns";
import md5 from "md5";
import type {
  IDefaultState,
  IDefaultStoreActions,
  IDefultStoreGetters,
  IImage,
} from "@/interfaces";
import type { StateCodes, TabErrorSchemaTypes } from "@/types";
import helpers from "@/helpers";
import type { Delta } from "quill/core";
import { Activity, Video } from "@/graphql/generated-types";
import { fixValidationFields } from "@/helpers/forms/validate";
import { globali18n } from "@/i18n";
import { NotificationSettingsInput } from "@/graphql/generated-types";

const VALIDATION_SCHEMA = joi.object({
  title: joi.string().max(50).required(),
  description: joi.string().max(2000).required(),
  startDate: joi.number().required(),
  endDate: joi.number().required().greater(joi.ref("startDate")),
  image: joi.required(),
});

const TAB_FIELDS_SCHEMA: TabErrorSchemaTypes = {
  main: ["title", "description"],
  pictures: ["image"],
};

const DEFAULT_START_TIME_FOR_NOW_VISIBILITY = "2017-01-01T00:00:00Z";
const SECOND_CHECKER = 15;
export const DEFAULT_NOTIFICATION_SETTINGS: Partial<NotificationSettingsInput> =
  {
    status: false,
    triggerWhenCancelled: true,
    triggerWhenTimeChange: true,
    reminderFirst: 1440,
    reminderSecond: 15,
  };

type VisibilityTypes = "now" | "beforeXDays" | "beforeXDate" | "onStartDate";
type VisibilityCategoryTypes = "beforeX" | "other";
type SignupCloseBeforeTypes = "disabled" | "beforeXDays" | "beforeXDate";

// Omit the type properties that are different in FE and redefine them here.
export type IActivitiesItem = Omit<Activity, "videos"> & {
  description: Delta | string;
  image?: IImage | string;
  visibility: VisibilityTypes;
  visibilityCategory: VisibilityCategoryTypes;
  visibleBeforeDays: number;
  visibilityStartDate: number;
  signup: boolean;
  signupCloseBefore: SignupCloseBeforeTypes;
  signupBeforeDays: number;
  signupStartDate: number;
  imageToUpload?: string;
  isRepeat?: boolean; // RepeatRule type or? maybe extend repeatActivity type too?
  status?: number;
  videos: Video[] | string[]; // overwrite videos type as we need to support string[] when Video exists on activity and Video[] when requesting an activity
  videoTabUrl: string; // URL input used to determine if should create a new Video resource in BE by comparing videos type.
  price?: string;
  vacation?: boolean;
};

export interface IActivitiesState extends IDefaultState {
  filter: string;
  item: IActivitiesItem;
  notification: NotificationSettingsInput;
}

export interface IActivitiesActions extends IDefaultStoreActions {
  setItemVisibility(visibility: VisibilityTypes): void;
}

export type ActivitiesStore = Store<
  string,
  IActivitiesState,
  IDefultStoreGetters<IActivitiesState>,
  IActivitiesActions
>;

export const createUseStore = (id: StateCodes) => {
  return defineStore(id, {
    state: (): IActivitiesState => ({
      searchText: "",
      filter: "show_upcomming",
      currentTab: "main",
      loading: false,
      tabErrors: {},
      originalHash: null,
      validation: null,
      item: {
        // todo fix types
        title: "",
        description: helpers.quill.getEmptyDelta(),
        location: "",
        startDate: toUnix(new Date()),
        endDate: toUnix(new Date()),
        image: undefined,
        /**
         * The visibility option. Possible values;
         *
         * now | beforeXDays | beforeXDate | onStartDate
         */
        visibility: "now",
        /**
         * This is the parent category of the user's selection
         */
        visibilityCategory: "other",
        visibleBeforeDays: 1,
        visibilityStartDate: toUnix(new Date()),
        signup: false,
        /**
         * If the sign up should be close before X. Possible values;
         *
         * disabled | beforeXDays | beforeXDate
         */
        signupCloseBefore: "disabled",
        signupBeforeDays: 1,
        signupStartDate: toUnix(new Date()),
        imageToUpload: undefined,
        videos: [],
      },
      notification: {
        ...DEFAULT_NOTIFICATION_SETTINGS,
      },
    }),
    actions: {
      currentHash(): string {
        const data = { ...this.getItem(), notification: this.notification };
        return md5(JSON.stringify(data));
      },
      setOriginalHash() {
        // It can take times to TextEditorInput convert html to delta.
        // That's why there is a timeout here.
        const timeout = setTimeout(() => {
          this.originalHash = this.currentHash();
          clearTimeout(timeout);
        }, 10);
      },
      async setItem(itemFromDatabase: any) {
        const authStore = useAuthStore();

        const item = { ...itemFromDatabase };
        item.videoTabUrl = item.videos?.[0]?.url ?? "";

        if (itemFromDatabase?.price?.[0]?.price) {
          item.price = itemFromDatabase.price[0].price / 100; // BE adds .00 to prevent currency issues.
        }

        item.location = item.location || "";
        item.description = await helpers.quill.htmlToDelta(
          item.description || "",
        );
        item.startDate = toUnix(new Date(item.startDate));
        item.endDate = toUnix(new Date(item.endDate));
        item.image = item.coverImage;
        item.toggle = false;

        // The default visibility options
        item.visibility = "now";
        item.visibilityCategory = "other";
        item.visibleBeforeDays = 1;
        item.visibilityStartDate = toUnix(new Date());

        const startDate = new Date(itemFromDatabase.startDate);
        const showDateStart = new Date(item.showDateStart);
        const defaultVisibilityDate = new Date(
          DEFAULT_START_TIME_FOR_NOW_VISIBILITY,
        );

        if (isEqual(showDateStart, defaultVisibilityDate)) {
          item.visibility = "now";
          item.visibilityCategory = "other";
        } else if (isEqual(showDateStart, startDate)) {
          item.visibility = "onStartDate";
          item.visibilityCategory = "other";
        } else {
          // We use the second to detect if we used the "days before" logic.
          // https://giphy.com/gifs/dunk-EXHHMS9caoxAA
          const second = parseInt(format(showDateStart, "ss"));
          item.visibilityCategory = "beforeX";
          if (second === SECOND_CHECKER) {
            item.visibility = "beforeXDays";
            item.visibleBeforeDays = differenceInDays(
              startDate,
              subSeconds(showDateStart, SECOND_CHECKER),
            );
          } else {
            item.visibility = "beforeXDate";
            item.visibilityStartDate = toUnix(showDateStart);
          }
        }

        // Signup options
        item.signup = item.signupPossible;
        item.signupCloseBefore = "disabled";
        item.signupBeforeDays = 1;
        item.signupStartDate = toUnix(new Date());

        // If there is a signup option, we might need to set values
        if (item.signup && item.signupStart) {
          // When the sign up will be started
          const signupStart = new Date(item.signupStart);

          // Check if there is any second
          const second = parseInt(format(signupStart, "ss"));

          if (second === SECOND_CHECKER) {
            // If there is, means we set a "X days before".
            item.signupCloseBefore = "beforeXDays";
            item.signupBeforeDays = differenceInDays(
              startDate,
              subSeconds(signupStart, SECOND_CHECKER),
            );
          } else {
            // Otherwise we should set the exact date
            item.signupCloseBefore = "beforeXDate";
            item.signupStartDate = toUnix(signupStart);
          }
        }
        this.item = item;

        // We should use the institution notificaton settings
        const baseNotifcationSettings: NotificationSettingsInput =
          authStore.profile?.institution.notificationSettings?.activity ||
          DEFAULT_NOTIFICATION_SETTINGS;

        this.notification = {
          ...baseNotifcationSettings,
          entityId: item.id,
          institution:
            item.institution?.id?.toString() || authStore.institutionId,
        };

        // Setting the internal notification state
        if (itemFromDatabase.notificationSettings) {
          const { status, reminderFirst, reminderSecond } =
            itemFromDatabase.notificationSettings;
          this.notification.status = status;
          this.notification.reminderFirst = reminderFirst;
          this.notification.reminderSecond = reminderSecond;
        }

        this.setOriginalHash();
        this.validation = null;
      },
      getItem(): IActivitiesItem {
        const authStore = useAuthStore();
        const item = { ...this.item };
        const startDate = toDate(item.startDate);

        // The default value of visibility dates
        let showDateStart = new Date(DEFAULT_START_TIME_FOR_NOW_VISIBILITY);

        // Setting the visibility dates
        if (item.visibility === "beforeXDays") {
          showDateStart = addSeconds(
            subDays(startDate, item.visibleBeforeDays),
            SECOND_CHECKER,
          );
        } else if (item.visibility === "beforeXDate") {
          showDateStart = toDate(item.visibilityStartDate);
        } else if (item.visibility === "onStartDate") {
          showDateStart = toDate(item.startDate);
        }

        // The default signup dates
        let signupStart;
        let signupEnd;

        // Setting the signup dates
        if (item.signup) {
          if (item.signupCloseBefore === "beforeXDays") {
            signupStart = addSeconds(
              subDays(startDate, item.signupBeforeDays),
              SECOND_CHECKER,
            );
            signupEnd = toDate(item.endDate);
          } else if (item.signupCloseBefore === "beforeXDate") {
            signupStart = toDate(item.signupStartDate);
            signupEnd = toDate(item.endDate);
          }
        }

        let image = item.image;
        if (typeof image === "object") {
          //@ts-ignore // TODO fix
          image = image.id;
        }

        return {
          id: item?.id?.toString(),
          institution:
            item.institution?.id?.toString() || authStore.institutionId,
          title: item.title,
          isRepeat: item.isRepeat || false,
          signup: item.signup,
          status: item.status || 1,
          image: image,
          startDate: fromUnixToAPIDate(item.startDate),
          endDate: fromUnixToAPIDate(item.endDate),
          showDateStart: fromUnixToAPIDate(toUnix(showDateStart)),
          showDateEnd: fromUnixToAPIDate(toUnix(toDate(item.endDate))),
          signupStart: signupStart
            ? fromUnixToAPIDate(toUnix(signupStart))
            : null,
          signupEnd: signupEnd ? fromUnixToAPIDate(toUnix(signupEnd)) : null,
          location: item.location,
          description: helpers.quill.deltaToHtml(item.description as Delta), // TODO: fix this delta typing
          imageToUpload: item.imageToUpload,
          videos: item.videos || [],
          ...(item.price && { price: parseFloat(item.price) }),
          // ...(item.vacation && { vacation: item.vacation }), // todo: https://proreact.atlassian.net/browse/IBG-7541
        };
      },
      setItemVisibility(visibility: VisibilityTypes) {
        this.item.visibility = visibility;
        if (["beforeXDays", "beforeXDate"].includes(visibility)) {
          this.item.visibilityCategory = "beforeX";
        } else {
          this.item.visibilityCategory = "other";
        }
      },
      isNotValid() {
        this.validation = helpers.forms.validate(VALIDATION_SCHEMA, {
          ...this.item,
          description: helpers.quill.deltaToText(this.item.description),
          image: this.item.image || this.item.imageToUpload,
        });

        // We can't use usei18n() in stores.
        // https://stackoverflow.com/a/71449708
        const { t } = globali18n;

        // We should fix the validation labels
        fixValidationFields(this.validation, {
          startDate: t("global.start"),
        });

        this.tabErrors = helpers.forms.getTabErrors(
          TAB_FIELDS_SCHEMA,
          this.validation.errors,
        );
        return !this.validation.isValid;
      },
    },
  });
};

// Activities View store
export const useActivitiesStore = createUseStore("ACTIVITIES");

// We use the same store structure for the create modal.
export const useAddActivitiessStore = createUseStore("ADD_ACTIVITIES");
